@import "../variables";
@import "shapes";

@mixin swatch($diameter, $color) {
    @include circle($diameter, $color);
    @include swatch-check-mark();

    border: 1px solid $base-color;
    display: inline-block;
    position: relative;

    // swatch-filter x mark to undo a filter
    i.fa-times-circle {
        background: $white;
        border-radius: 50%;
        height: 0.75em;
        line-height: 0.8;
        width: 0.8em;
    }
}

@mixin swatch-check-mark() {
    &.color-value[data-selected=true] {
        border: 1px solid #FFF;
    }

    &.color-value.selected {
        border: 1px solid #FFF;
    }
}

$swatch-colors: (
    "beige": #F5F5DC,
    "black": #0F0E0C,
    "blue": #4C6478,
    "brown": #A67D5D,
    "green": #7A826B,
    "grey": #ABABAB,
    "gray": #ABABAB,
    "navy": navy,
    "orange": #E7A73B,
    "pink": #FDCAC6,
    "purple": #B13E65,
    "red": #B43F3F,
    "white": #F6F5F1,
    "yellow": #F6DC8B,
    "neutral": #FFF5EE,
    "gold": #FFD700,
    "silver": #C0C0C0,
    "tan": #D2B48C,
    "indigo": #001A41,
    "heather-grey": #C7C7C7,
    'camo': #9D9684,
    "wine": #501D27,
    "rust": #844735,
    'jewel': #244355,
    "olive": #48522C,
    "midnight": #8090B1,
    "honey": #DFAE69,
    "dark-tan": #834438,
    "dark-brown": #403232,
    "dark-blue": #272A37,
    "burgundy": #812A2E,
);

.swatch-circle {
    @include circle(1.5rem, #FFF);

    display: block;
    position: relative;
    border: 1px solid $base-color;

    &.disabled {
        opacity: 0.2;
    }
}

@each $color in map-keys($swatch-colors) {
    $colorValue: #{map-get($swatch-colors, $color)};

    .swatch-circle-#{$color} {
        @include circle(1.5rem, $colorValue);
    }

    .swatch-filter-#{$color} {

        @include circle(1.5rem, $colorValue);

        background-color: $colorValue;
        display: block;
        position: relative;

        &.disabled,
        &.unselectable {
            opacity: 0.2;
        }

        &.selected {
            border: 1px solid $white;
            outline: 0.5px solid $black;
        }
    }
}

// Credit: Christian Muecke (cmuecke@demandware.com)
.swatch-circle-miscellaneous {
    background:
        linear-gradient(
            0deg,
            rgba(130, 30, 145, 1) 0,
            rgba(130, 30, 145, 1) 25%,
            rgba(237, 209, 52, 1) 25%,
            rgba(255, 255, 0, 1) 50%,
            rgba(237, 209, 52, 1) 50%,
            #59BA00 50%,
            #59BA00 76%,
            #111 76%,
            #111 100%
        ),
        linear-gradient(
            0deg,
            rgba(14, 92, 209, 1) 0,
            rgba(14, 92, 209, 1) 50%,
            rgba(226, 11, 11, 1) 50%,
            rgba(226, 11, 11, 1) 100%
        );
    background-repeat: repeat-y, repeat;
    background-size: 50% 100%, 100% 100%;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    border-radius: 0.75rem;
    display: block;
    height: 1.5rem;
    position: relative;
    transform: rotate(35deg);
    width: 1.5rem;

    &.disabled {
        opacity: 0.2;
    }

    &.selected::after {
        transform: rotate(-35deg);
    }
}
