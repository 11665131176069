@import "../variables";

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 13px;
}

*::-webkit-scrollbar-track {
    background: $white;
}

*::-webkit-scrollbar-thumb {
    background-color: $grey-d9;
    border-radius: 8px;
    border: 3px solid $white;
}
