// variables
// --------------------------

$fa-css-prefix            : fa !default;
$fa-style                 : 900 !default;
$fa-style-family          : "Font Awesome 6 Pro" !default;

$fa-icon-property         : --fa;
$fa-duotone-icon-property : --fa--fa;

$fa-display               : inline-block !default;

$fa-fw-width              : fa-divide(20em, 16) !default;
$fa-inverse               : #fff !default;

$fa-border-color          : #eee !default;
$fa-border-padding        : .2em .25em .15em !default;
$fa-border-radius         : .1em !default;
$fa-border-style          : solid !default;
$fa-border-width          : .08em !default;

$fa-size-scale-2xs        : 10 !default;
$fa-size-scale-xs         : 12 !default;
$fa-size-scale-sm         : 14 !default;
$fa-size-scale-base       : 16 !default;
$fa-size-scale-lg         : 20 !default;
$fa-size-scale-xl         : 24 !default;
$fa-size-scale-2xl        : 32 !default;

$fa-sizes: (
  "2xs"                   : $fa-size-scale-2xs,
  "xs"                    : $fa-size-scale-xs,
  "sm"                    : $fa-size-scale-sm,
  "lg"                    : $fa-size-scale-lg,
  "xl"                    : $fa-size-scale-xl,
  "2xl"                   : $fa-size-scale-2xl
) !default;

$fa-li-width              : 2em !default;
$fa-li-margin             : $fa-li-width * fa-divide(5, 4) !default;

$fa-pull-margin           : .3em !default;

$fa-primary-opacity       : 1 !default;
$fa-secondary-opacity     : .4 !default;

$fa-stack-vertical-align  : middle !default;
$fa-stack-width           : ($fa-fw-width * 2) !default;
$fa-stack-z-index         : auto !default;

$fa-font-display          : block !default;
$fa-font-path             : "../webfonts" !default;

$fa-var-arrow-right-from-bracket: \f08b;
$fa-var-sign-out: \f08b;
$fa-var-arrow-up: \f062;
$fa-var-bag-shopping: \f290;
$fa-var-shopping-bag: \f290;
$fa-var-bars: \f0c9;
$fa-var-navicon: \f0c9;
$fa-var-cart-arrow-up: \e3ee;
$fa-var-check: \f00c;
$fa-var-chevron-down: \f078;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-chevron-up: \f077;
$fa-var-circle-check: \f058;
$fa-var-check-circle: \f058;
$fa-var-clothes-hanger: \e136;
$fa-var-envelope: \f0e0;
$fa-var-gift: \f06b;
$fa-var-grid-2: \e196;
$fa-var-hand-holding-dollar: \f4c0;
$fa-var-hand-holding-usd: \f4c0;
$fa-var-heart: \f004;
$fa-var-location-dot: \f3c5;
$fa-var-map-marker-alt: \f3c5;
$fa-var-magnifying-glass: \f002;
$fa-var-search: \f002;
$fa-var-memo-circle-info: \e49a;
$fa-var-minus: \f068;
$fa-var-subtract: \f068;
$fa-var-notdef: \e1fe;
$fa-var-phone: \f095;
$fa-var-plus: \2b;
$fa-var-add: \2b;
$fa-var-spinner: \f110;
$fa-var-square: \f0c8;
$fa-var-truck: \f0d1;
$fa-var-user: \f007;
$fa-var-user-check: \f4fc;
$fa-var-xmark: \f00d;
$fa-var-close: \f00d;
$fa-var-multiply: \f00d;
$fa-var-remove: \f00d;
$fa-var-times: \f00d;

$fa-var-cc-amex: \f1f3;
$fa-var-cc-apple-pay: \f416;
$fa-var-cc-discover: \f1f2;
$fa-var-cc-mastercard: \f1f1;
$fa-var-cc-paypal: \f1f4;
$fa-var-cc-stripe: \f1f5;
$fa-var-cc-visa: \f1f0;
$fa-var-facebook: \f09a;
$fa-var-facebook-f: \f39e;
$fa-var-google: \f1a0;
$fa-var-google-pay: \e079;
$fa-var-instagram: \f16d;
$fa-var-pinterest-p: \f231;
$fa-var-snapchat: \f2ab;
$fa-var-snapchat-ghost: \f2ab;
$fa-var-tiktok: \e07b;
$fa-var-twitter: \f099;
$fa-var-x-twitter: \e61b;
$fa-var-youtube: \f167;

$fa-icons: (
  "arrow-right-from-bracket": $fa-var-arrow-right-from-bracket,
  "sign-out": $fa-var-sign-out,
  "arrow-up": $fa-var-arrow-up,
  "bag-shopping": $fa-var-bag-shopping,
  "shopping-bag": $fa-var-shopping-bag,
  "bars": $fa-var-bars,
  "navicon": $fa-var-navicon,
  "cart-arrow-up": $fa-var-cart-arrow-up,
  "check": $fa-var-check,
  "chevron-down": $fa-var-chevron-down,
  "chevron-left": $fa-var-chevron-left,
  "chevron-right": $fa-var-chevron-right,
  "chevron-up": $fa-var-chevron-up,
  "circle-check": $fa-var-circle-check,
  "check-circle": $fa-var-check-circle,
  "clothes-hanger": $fa-var-clothes-hanger,
  "envelope": $fa-var-envelope,
  "gift": $fa-var-gift,
  "grid-2": $fa-var-grid-2,
  "hand-holding-dollar": $fa-var-hand-holding-dollar,
  "hand-holding-usd": $fa-var-hand-holding-usd,
  "heart": $fa-var-heart,
  "location-dot": $fa-var-location-dot,
  "map-marker-alt": $fa-var-map-marker-alt,
  "magnifying-glass": $fa-var-magnifying-glass,
  "search": $fa-var-search,
  "memo-circle-info": $fa-var-memo-circle-info,
  "minus": $fa-var-minus,
  "subtract": $fa-var-subtract,
  "notdef": $fa-var-notdef,
  "phone": $fa-var-phone,
  "plus": $fa-var-plus,
  "add": $fa-var-add,
  "spinner": $fa-var-spinner,
  "square": $fa-var-square,
  "truck": $fa-var-truck,
  "user": $fa-var-user,
  "user-check": $fa-var-user-check,
  "xmark": $fa-var-xmark,
  "close": $fa-var-close,
  "multiply": $fa-var-multiply,
  "remove": $fa-var-remove,
  "times": $fa-var-times,
);

$fa-brand-icons: (
  "cc-amex": $fa-var-cc-amex,
  "cc-apple-pay": $fa-var-cc-apple-pay,
  "cc-discover": $fa-var-cc-discover,
  "cc-mastercard": $fa-var-cc-mastercard,
  "cc-paypal": $fa-var-cc-paypal,
  "cc-stripe": $fa-var-cc-stripe,
  "cc-visa": $fa-var-cc-visa,
  "facebook": $fa-var-facebook,
  "facebook-f": $fa-var-facebook-f,
  "google": $fa-var-google,
  "google-pay": $fa-var-google-pay,
  "instagram": $fa-var-instagram,
  "pinterest-p": $fa-var-pinterest-p,
  "snapchat": $fa-var-snapchat,
  "snapchat-ghost": $fa-var-snapchat-ghost,
  "tiktok": $fa-var-tiktok,
  "twitter": $fa-var-twitter,
  "x-twitter": $fa-var-x-twitter,
  "youtube": $fa-var-youtube,
);

$fa-custom-icons: (
);
