@import "variables";

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            border-bottom: 1px solid $grey-400;

            .title {
                line-height: 2.5; /* 40/16 */
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                @include clearfix;

                &::after {
                    content: none;
                }

                .fa-chevron-down {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            .content,
            .card-body {
                display: none;
            }

            &.active {
                .title {
                    .fa-chevron-down {
                        transform: rotate(180deg);
                        margin-top: -0.125em; /* 2/16 */
                    }
                }

                .content,
                .card-body {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }
        }
    }

    .container div.collapsible-#{$size} button.title {
        color: $black;
        text-decoration: none;
        border: none;
        background-color: transparent;

        &:hover {
            text-decoration: none;
        }
    }
}
