@import "../utilities/swatch";
@import "../variables";

.search-container {
    position: absolute;
    z-index: 1080;
    top: 0;
    left: 0;
    width: 100%;
}

.page {
    position: relative;
}

.site-search {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: $white;
    z-index: 10;

    .card-header {
        border: none;
    }

    .fa-close,
    .fa-magnifying-glass {
        position: absolute;
        border: none;
        top: 0.5625em; /* 9/16 */
        right: 0.5625em; /* 9/16 */
        padding: 0;
        background-color: transparent;
    }

    .reset-button {
        position: absolute;
        border: none;
        top: 0.5625em; /* 9/16 */
        right: 2em; /* 32/16 */
        padding: 0;
        background-color: transparent;
    }

    input {
        padding-right: 2rem;
    }

    form {
        &[role="search"] {
            position: relative;
        }
    }
}

@include media-breakpoint-down(xs) {
    .header-search {
        .site-search {
            display: none;
        }
    }
}

.suggestions-wrapper {
    margin-top: 2rem;
    position: relative;
}

.suggestions {
    .swatch-square {
        width: auto;
        height: 11.5em;
        background: $white;
        display: inline-block;
        margin-right: 0.313em;
        position: relative;
    }

    .header {
        font-size: 1.125rem;
        font-weight: 400;
        padding-top: 0.625em;

        .suggestion--header__style {
            font-size: 1.125rem;
        }

        &:not(:first-child) {
            border-top: 1px solid $grey3;
        }
    }

    .items {
        font-size: 1rem;
        font-weight: 400;
    }

    .item {
        font-size: 1rem;
        font-weight: 400;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .product-suggestion--anchor__style {
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
        }

        .product-suggestion--description__style {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 1.5rem;
        }

        .product-suggestion-name {
            text-transform: uppercase;
            margin-top: 0.313em;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .product-suggestion-price {
            text-transform: uppercase;
        }

        .name {
            margin-top: 0.313em;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include media-breakpoint-down(xs) {
            padding-bottom: 0.938em;
        }
    }

    .category-parent {
        color: $grey4;
        font-size: 0.875em;
    }

    .selected {
        background-color: $grey2;
    }

    .container {
        list-style-type: none;
    }
}

.more-below {
    -moz-border-radius: 1.25em;
    background: $grey3;
    border: 0.063em solid rgba(0, 0, 0, 0.1);
    border-radius: 1.25em;
    bottom: 1.875em;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
    display: none;
    height: 2.5em;
    position: fixed;
    right: 1.875em;
    width: 2.5em;

    i.fa-long-arrow-down {
        border-radius: 50%;
        color: $white;
        display: table-caption;
        height: 0.75em;
        font-size: 1.5rem;
        left: 0.57em;
        line-height: 0.8;
        position: absolute;
        top: 0.4em;
        width: 0.8em;
    }
}
