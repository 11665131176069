@import "../variables";

.font-weight-thin {
    font-weight: $font-weight-thin;
}

.font-weight-extralight {
    font-weight: $font-weight-extraLight;
}

.font-weight-light {
    font-weight: $font-weight-light;
}

.font-weight-regular {
    font-weight: $font-weight-regular;
}

.font-weight-medium {
    font-weight: $font-weight-medium;
}

.font-weight-semibold {
    font-weight: $font-weight-semiBold;
}

.font-weight-bold {
    font-weight: $font-weight-bold;
}

.font-weight-extrabold {
    font-weight: $font-weight-extraBold;
}

.font-weight-black {
    font-weight: $font-weight-black;
}

.font-sans {
    font-family: $font-family-sans-serif;
}

.font-sans-condensed {
    font-family: $font-family-sans-serif-condensed;
}

.font-serif {
    font-family: $font-family-serif;
}

// This style rule is kept here for backwards compatability but should not be used
.Lato {
    font-family: $font-family-sans-serif;
}

.font-weight-huskie {
    font-weight: $font-weight-huskie;
}

dd {
    margin-bottom: $paragraph-margin-bottom;
}

ul.unorganized-list {
    padding-left: 1rem;

    li {
        padding-left: .25rem;

        &::marker {
            content: '\30FB';
        }
    }
}
