@import "bootstrap/scss/functions";

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../webfonts";

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/" !default;

// Colour System
$white: #FFF !default;
$black: #101010 !default;
$grey-75: #757575 !default;
$grey-70: #707070 !default;
$grey-d9: #D9D9D9 !default;
$grey-f2: #F2F2F2 !default;
$grey-c4: #C4C4C4 !default;
$grey-200: #BFBFBF;
$grey-400: #6B6B6B; // Inactive colour
$blue: #0070D2 !default;
$green: #008827 !default;
$red: #C9291C !default;
$success: $green !default;
$light-blue: #7ED0EE !default;
$dark: $black;
$midnight-blue: #012A4A !default;

// primary is replaced by css variable --skin-primary-color-1
$primary: $black;
$secondary: $grey-400;
$danger: $red !default;

// Consolidated values
$grey1: #F9F9F9 !default;
$grey2: #EEE !default;
$grey3: #CCC !default;
$grey4: #999 !default;
$grey5: #666 !default;
$grey6: #444 !default;
$grey7: #222 !default;
$grey8: #333 !default;
$grey-transparent-1: rgba(0, 0, 0, 0.65) !default;
$grey-transparent-2: rgba(0, 0, 0, 0.25) !default;

$light-gray: $grey1 !default;
$slightly-darker-gray: $grey4 !default;
$dark-gray: $grey6 !default;
$darker-gray: $grey7 !default;
$horizontal-rule-grey: $grey3 !default;
$product-number-grey: $grey3 !default;
$horizontal-border-grey: $grey4 !default;
$menu-link: $black !default;
$close-menu-bg: $grey2 !default;
$link-color: $dark-gray !default;
$hr-border-color: $grey3 !default;
$fa-color: $black !default;
$base-color: $black !default;
$reduced-price-color: $red !default;

$grid-gutter-width: 32px !default;

$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 1220px,
    lg: 1440px,
    xl: 1728px
);

$container-max-widths: (
    sm: 960px,
    md: 1152px,
    lg: 1248px,
    xl: 1440px
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 2),
        6: ($spacer * 3),
        7: ($spacer * 3.75),
        8: ($spacer * 4.5)
    ),
    $spacers
);

$state-danger-text: #FFF !default;
$alert-success-text: #FFF !default;

// Font sizes
$base16-14px: 0.875em !default;

// Bootstrap overrides
$body-bg: $grey1 !default;
$card-cap-bg: $white !default;

// Tabs
$nav-tabs-border-width: 0 !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-active-link-hover-bg: transparent !default;

// breadcrumb
$breadcrumb-bg: transparent !default;

// table border
$table-border-color: $grey3 !default;

$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;

// Typography
// stylelint-disable value-keyword-case
$font-family-serif:                     "Playfair Display", serif;
$font-family-sans-serif:                "Barlow", "Arial", "Helvetica Neue", Arial, sans-serif;
$font-family-sans-serif-condensed:      "Barlow Semi Condensed", "Arial Narrow", "Franklin Gothic Medium", sans-serif;
$font-family-sans-serif-semi-condensed: "Barlow Semi Condensed", "Arial Narrow", sans-serif;
$font-family-monospace:                 SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:                      $font-family-sans-serif !default;
$font-family-icon:                      "Font Awesome 6 Pro";
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default;
$line-height-base:            1.5;

$paragraph-margin-bottom:   1rem !default;

// Font family
$base-font-family: $font-family-base !default;

// Font weight
$base-font-weight: 400 !default;
$font-weight-huskie: 700;
$font-weight-heavy: 700 !default;
$font-weight-heavier: 900 !default;
$font-weight-thin: 100;
$font-weight-extraLight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semiBold: 600;
$font-weight-bold: 700;
$font-weight-extraBold: 800;
$font-weight-black: 900;

//z-index
$z-index-modal: 1100;
$z-index-modal-backdrop: 1070;
$z-index-minicart-popover: 1080;
$z-index-minicart: 1060;
$z-index-tracking-consent: 1000;
$z-index-toast-message: 1100;
$z-index-overlay: 1080;
$z-index-sticky-header: 1070;

$h1-font-size:                $font-size-base * 3.5;
$h2-font-size:                $font-size-base * 3;
$h3-font-size:                $font-size-base * 2.5;
$h4-font-size:                $font-size-base * 2;
$h5-font-size:                $font-size-base * 1.5;
$h6-font-size:                $font-size-base * 1.25;

// Components
$border-color:              $grey-400;
$border-radius:             0;
$border-radius-lg:          $border-radius;
$border-radius-sm:          $border-radius;
$component-active-color:    $black;
$component-active-bg:       transparent;

$bg-primary: $white;
$bg-secondary: $black;
$bg-inactive: $grey-200;

$text-primary: $black;
$text-secondary: $grey-400;
$text-inverted: $white;

// Button and Form Elements
$input-btn-padding-y:         .5rem;

// Form Elements

$input-padding-x:                   0;
$custom-select-indicator:           url('../images/icons/arrow-down.svg');
$custom-select-border-radius:       0;
$input-bg:                          transparent;

// Menu Heights to help offset other sticky elements
$mobile-header-height: 5.6875rem; // Webkit seems to render header shorter than other browsers
$desktop-header-height: 8rem;
