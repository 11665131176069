.sizechart {
    &__table {
        border-bottom: 1px dashed $black;

        td {
            padding: 1.25rem .75rem;
            border-top: 1px dashed $black;
        }
    }

    &__tips {
        &-container {
            position: relative;
        }

        &-wrapper {
            @include media-breakpoint-up(md) {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
            }
        }

        &-image {
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }

    .table-hover {
        tbody tr {
            &:hover {
                color: $night-air;
                background-color: $seashell;
            }
        }
    }
}

.sizeChartModal {
    padding: 0;

    .modal-dialog {
        top: 7.5vh;
        width: 80vw;
        max-width: 64rem;
        height: 85vh;
        min-height: unset;
        margin: 0 auto;

        .modal-content {
            height: 100%;
            overflow-y: auto;
            border: none;

            .modal-header {
                border: none;
                background-color: $white;

                .size-chart__header-description {
                    font-size: 1.125rem;
                }

                .close {
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}