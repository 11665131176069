@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.timeline-container {
    position: relative;
    margin-left: 9rem;
}

@include media-breakpoint-down(sm) {
    .timeline-container {
        margin-left: 50%;
    }
}

.timeline__line {
    border-right: 1px solid #B8B8B8;
    left: 0.7rem;
    position: absolute;
    top: 0;
    height: 100%;
}

.timeline__items {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.timeline__item {
    margin-bottom: 4rem;
}

.timeline__circle {
    position: absolute;
    background-color: $white;
    border: 1px solid #B8B8B8;
    border-radius: 9999px;
    z-index: 1;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.timeline__date {
    color: #757575;
    position: absolute;
    left: -9rem;
}

.timeline__title {
    margin-left: 3rem;
    position: relative;

    .timeline__location {
        position: absolute;
    }
}

.timeline__item {
    &:has(~ .timeline__item__active):before {
        background-color: $black;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0.7rem;
        width: 0.1em;
        height: 7em;
        z-index: 0;
    }
}

.timeline__item__active {
    .timeline__circle__checked {
        background-color: $black;
        border-radius: 9999px;
        height: 0.95rem;
        width: 0.95rem;
    }

    .timeline__circle__checked_error {
        background-color: $red;
        border-radius: 9999px;
        height: 0.95rem;
        width: 0.95rem;
    }
}

.timeline__item:has(.timeline__item__active) .timeline__circle__checked {
    background-color: $black;
    border-radius: 9999px;
    height: 0.95rem;
    width: 0.95rem;
}

.shipping_error_msg {
    color: $red;
}
