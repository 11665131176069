$breakpoint-name: 'sm';
$breakpoint-name: 'sm' !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
    font-family: 'Font Awesome 6 Pro';
    content: '\F053';
    border: none;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: none;
}

@mixin caret-right() {
    font-family: 'Font Awesome 6 Pro';
    content: '\F054';
    border: none;
    position: absolute;
    right: 1.5em;
    margin-top: none;
}

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            transition: $slide-out-animation;
            display: block;
            max-width: 100%;

            &.in {
                min-width: 100%;
                left: 0;
            }
        }
    }
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 0;

    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: 0;
        }

        .navbar-nav .nav-link {
            padding: 0 0.8rem;
            white-space: nowrap;
        }

        .navbar-nav > .nav-item {
            text-transform: capitalize;

            .category__banner-button {
                text-transform: capitalize;
            }
        }
    }
}

.navbar-expand-md .navbar-nav.nav-center {
    justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
    justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
    justify-content: end;
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: $white;
    color: $menu-link;
}

@include media-breakpoint-up($next-breakpoint) {
    .nav-item > .nav-link {
        text-decoration: none;
    }
}

.main-menu.menu-toggleable-left {

    @include media-breakpoint-down($breakpoint-name) {
        background-color: $white;
        z-index: 4;
    }
}

.menu-toggleable-left {

    @include media-breakpoint-down($breakpoint-name) {
        overflow: auto;
    }

    .close-menu {
        padding: 15px;
        background-color: $white;
        flex: 0 0 100%;

        @include media-breakpoint-up($next-breakpoint) {
            display: none;
        }
    }

    .menu-group {
        flex: 0 0 100%;
    }

    li > .close-menu {
        margin-right: 0;
        margin-top: -0.6rem;
        margin-left: 0;
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: white !important;
            color: $grey7;
        }
    }

    &.in {

        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
        }

        @include media-breakpoint-down($breakpoint-name) {
            .dropdown {
                display: block;
                position: static;
            }

            .dropdown-toggle {
                padding-left: 1rem;

                &::after {
                    @include caret-right();
                }
            }

            .nav-item .nav-link {
                padding-left: 1rem;
                text-decoration: none;

                &:focus,
                &:hover {
                    text-decoration: underline;
                }
            }

            .nav-item {
                margin-left: 1rem;

                .dropdown-link {
                    text-decoration: none;

                    &:focus,
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .show > .dropdown-menu {
                left: 0;
            }

            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
            }
        }
    }

    .menu-category-title {
        background: #F6F6F6;
        min-height: 3em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

#maincontent {
    position: relative;
    min-height: 50vh;

    .navbar-background {
        background-color: $black;
        display: none;
        height: 100%;
        position: absolute;
        opacity: 0.5;
        width: 100%;
        top: 0;
        left: 0;
    }
}

.multilevel-dropdown {
    .country-selector {
        .dropdown-menu {
            min-width: auto;
        }

        .dropdown-toggle {
            font-weight: $base-font-weight;
            padding-left: 2rem !important;
        }
    }

    .dropdown-country-selector {
        .top-category {
            font-weight: $font-weight-heavy;
        }
    }

    .dropdown-menu {
        top: 100%;
        border: 0;
        border-radius: 0;

        @include media-breakpoint-up(md) {
            width: 100%;

            ul {
                &.list--parent__columns {
                    list-style-type: none;
                }

                &.category__levelTwo {
                    display: flex;
                    justify-content: space-between;
                    list-style-type: none;

                    .category__levelThree {
                        display: flex;
                        flex-direction: column;
                    }
                }

                &.grid-template-columns-1 {
                    grid-template-columns: repeat(1, 1fr);
                }

                li {
                    list-style-type: none;

                    a {
                        text-decoration: none;
                        text-transform: capitalize;
                    }
                }
            }

            .category__banner-container {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    object-fit: cover;
                }

                .category__banner-wrapper {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    .category__banner-button {
                        width: 100%;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .dropdown-menu {
            border-top: 0.5px solid $grey-75;
            margin: 0;
            margin-top: -1px;
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle::after {
        @include caret-right();
    }

    .dropdown-menu > .dropdown > .dropdown-menu {

        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            @include caret-left();
        }
    }

    .dropdown-country-selector {
        .top-category {
            background: transparent !important;
            justify-content: flex-start !important;
        }

        .dropdown-item {
            text-decoration: none;

            &:focus,
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .dropdown-item {
        padding: 0;

        &.active,
        &:active {
            background-color: #F6F6F6;
        }

        .dropdown-link {
            display: block;
            padding: 0.425em 5em 0.425em 1rem;
        }

        &.top-category {
            font-size: 1rem;
            background: #F6F6F6;
            text-align: center;
            min-height: 3em;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-top: 1.5rem;

            a {
                background: transparent;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }

            > .nav-link {
                padding-left: 0;
            }
        }

        &.active,
        &:active {
            background-color: transparent;
        }
    }
}

.mobile-global-action {
    border-top: 0.5px solid $grey-75;
}

.global-tools .country-selector .dropdown-toggle,
.global-actions .country-selector .dropdown-toggle {
    transition: none;

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
    }

    .dropdown-menu {
        min-width: auto;
    }

    + .dropdown-menu {
        min-width: 5rem;
    }

    &::after {
        font-family: 'Font Awesome 6 Pro';
        content: '\F078';
    }

    + .dropdown-country-selector {
        .dropdown-item {
            padding: 0.5rem;
            display: flex;
            justify-content: center;
            text-decoration: none;

            &.active,
            &:active {
                background-color: #F6F6F6;
            }

            .visually-hidden {
                display: none;
            }
        }
    }

    &:focus {
        border: 0.5px dashed #000;
        box-shadow: none;
    }
}

.nav-link.btn.c-newsletter__button {
    @include media-breakpoint-down(sm) {
        color: $menu-link;
        font-size: 1rem;
    }
}
