.inseamGuideModal {
    .modal-open & {
        padding-right: 0 !important;
    }

    .modal-dialog {
        top: 7.5vh; /* (100vh - 85vh) / 2 */
        width: 80vw;
        max-width: 64rem;
        height: 85vh;
        min-height: unset;
        margin: 0 auto;
    }

    .modal-header {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: transparent;
        border: none;
    }

    .close::after {
        content: none;
    }

    .modal-content {
        height: 100%;
        overflow-y: auto;
    }

    .modal-body {
        height: 100%;
        padding: 0;
    }

    .row {
        height: 100%;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            overflow: hidden;
        }
    }
}

.inseam-guide {
    height: 100%;

    &__image {
        object-fit: cover;
        object-position: 70% 70%;

        @include media-breakpoint-down(sm) {
            max-height: 75%;
        }
    }

    &__text-container {
        align-self: center;
        overflow-y: auto;

        @include media-breakpoint-up(md) {
            max-height: 100%;
        }
    }
}
