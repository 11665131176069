.header-aside {
    background-color: $menu-link;

    &__grid {
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 1fr minmax(45%, 25rem) 1fr;
            align-items: center;
        }
    }

    .global-tools {
        grid-column-start: 3;
    }
}

.message-container {
    grid-column-start: 2;
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 36rem;
    font-size: .875rem;
    line-height: 1.2;
    color: $white;
}

.header-aside__carousel {
    width: calc(100% - .75rem);
    height: auto;

    .details-button {
        cursor: pointer;
    }

    .splide__list {
        display: flex;
        align-items: center;
    }

    .splide__slide {
        text-align: center;

        p,
        a {
            display: inline;
            margin: 0;
        }
    }

    .splide__arrow {
        background: transparent;
        opacity: 1;

        svg {
            fill: $white;
            stroke: $menu-link;
            stroke-width: 3px;
        }

        &--prev {
            left: -1.5rem;
        }

        &--next {
            right: -1.5rem;
        }

        &:disabled {
            visibility: hidden;
        }
    }
}

.btn.c-newsletter__button,
.btn.c-track-order {
    padding-right: 0;
    color: $white;
    font-size: .875rem;
}
