@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

#emailSignupPopup {
    top: 50% !important;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 1100;
    background-color: $white;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    width: 90%;
    max-width: 44rem;
    max-height: 85vh;
    /* stylelint-disable unit-no-unknown */
    max-height: 85svh;
    /* stylelint-enable unit-no-unknown */

    a.emailSignupNoThanks:before {
        content: '\F00D';
        display: inline-block;
        font-family: Font Awesome\ 6 Pro;
        font-weight: 300;
        font-size: 1.5rem;
        width: 18px;
        height: 21px;
        position: absolute;
        z-index: 10;
        top: 10px;
        right: 20px;
        color: $black;
    }

    .subscribe-container {
        height: 0;
        padding-bottom: 37.5rem;
    }

    iframe {
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
