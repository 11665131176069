.breadcrumb {
    margin: 0;
    padding: 0;
    font-size: 0.875rem;

    .breadcrumb-item {
        &:not(:last-child)::after {
            display: inline-block;
            padding: 0 .5rem;
            // color: #000;
            content: ">";
            text-decoration: none;
        }

        a {
            text-transform: uppercase;

            &:last-child {
                text-decoration: none;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .breadcrumb-item + .breadcrumb-item {
        padding: 0;

        &::before {
            display: none;
        }
    }
}

