@import "../utilities/swatch";
@import "bootstrap/scss/mixins/breakpoints";
@import "productCommon";
@import "sizeChart";

.quick-view__quantity--grid {
    display: flex;
    justify-content: space-between;
}

.quick-view-dialog,
.choose-bonus-product-dialog {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;

    @include media-breakpoint-down(sm) {
        width: 100vw;
        max-width: 100vw;
        height: 100vw;
    }

    label,
    .color-val {
        text-transform: uppercase;
    }

    .selectable-bonus-product-line-item {
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .beenSelected {
        background-color: $grey2;
    }

    .modal-header {
        background-color: $white;
        border-bottom: none;
        border-top-left-radius: 0.1875rem;
        border-top-right-radius: 0.1875rem;

        .full-pdp-link {
            color: #{var(--skin-primary-color-1)};
        }

        .close {
            font-size: 2rem;
            line-height: 1.5;

            &::after {
                content: none;
            }
        }
    }

    .modal-title {
        font-size: 1em;
    }

    .product-name {
        font-size: 1.25rem;
    }

    .product-sku {
        color: $grey-75;
        font-size: 0.875rem;
    }

    .prices {
        text-align: left;

        .sales {
            font-weight: $font-weight-heavy;
            font-size: 1rem;
            margin-right: 2rem;
        }
    }

    .product-tile_badges {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: center;
        justify-content: flex-start;
        align-items: center;

        .product-tile_badge-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;

            &.product-tile_badge-eco {
                background-color: #8BB69B;
                color: $black;
            }

            &.product-tile_badge-new {
                background-color: $grey-75;
                color: $white;
            }

            span {
                text-transform: uppercase;
                font-family: $base-font-family;
                font-style: normal;
                font-weight: $font-weight-heavy;
                font-size: 0.875rem;
                line-height: 1;
                text-align: center;
            }
        }
    }

    .color-attribute {
        border-radius: 100%;

        &.selected {
            outline: 1px solid $black;
            border-radius: 100%;
            outline-offset: 2px;
        }

        &.focus,
        &:focus {
            outline: 1px solid $black;
            border-radius: 100%;
            outline-offset: 0;
        }

        &.unselectable {
            opacity: 0.3;
        }
    }

    .size-chart__wrapper {
        display: flex;
        flex-direction: column;

        .size-chart__filter-header {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
    }

    .product__variation-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .product-attr {
        list-style: none;
        padding: 0;

        &.product-attr__orientation {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
        }

        .product-attr__option {
            button {
                color: $black;
                font-family: $base-font-family;
                font-style: normal;
                font-weight: $base-font-weight;
                font-size: 100%;
                line-height: 1;
                background: transparent;
                border: 0.5px solid $grey-d9;
                min-height: 2.438rem;
                min-width: 2.438rem;
                margin: 1px;

                &.selected {
                    color: $white;
                    background: $black;
                }

                &:hover,
                &:focus {
                    color: $white;
                    background: $black;
                }

                &:disabled {
                    color: $grey-75;
                    background: $grey-d9;
                    border: 0.5px solid $grey-d9;
                }
            }
        }
    }

    .stepper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3.375rem;
        width: 100%;

        @include media-breakpoint-down(sm) {
            height: 2.625rem;
        }
    }

    .quickview__update-btn--grid {
        display: flex;
        align-items: flex-end;

        @include media-breakpoint-down(xs) {
            display: unset;
            align-items: unset;
        }

        button {
            padding: 14px 40px;

            @include media-breakpoint-down(sm) {
                padding: 8px 30px;
            }
        }
    }

    .quantity__orientation {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: unset;
        }
    }

    a[disabled] .swatch-circle {
        cursor: not-allowed;

        &.color-value.selected::after {
            background-color: $gray-700;
        }
    }

    .availablity-container {
        text-align: right;
    }

    .availablity-container,
    .size-chart {
        margin-top: 0.938em;
    }

    .modal-content {
        width: 71vw;
        height: 71vh;
        max-width: 100vw;
        max-height: 100vh;
        border: none;
        position: absolute;
        top: 14%;
        left: 14%;
    }

    .modal-body {
        max-height: 100%;
        overflow-y: auto;
        flex: unset;
    }

    button.close {
        font-size: 1.25em;
    }

    .modal-footer {
        background-color: $white;
        border: none;
        border-bottom-right-radius: 0.1875rem;
        border-bottom-left-radius: 0.1875rem;

        .prices .price {
            font-size: 1.6em;
        }
    }

    .promotions {
        text-align: left;
        color: $red;
    }

    .bonus-summary {
        @include media-breakpoint-down(sm) {
            font-size: 0.625em;
        }

        .bonus-product-name {
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }
    }

    .pre-cart-products {
        margin-right: 0.125em;
    }

    .color-attribute {
        border: none;
        padding: 0;
        background: none;
    }

    .non-input-label {
        display: block;
        margin-bottom: 0.5rem;
    }
}
