@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./productCard";
@import "./components/quantitySelector";

.minicart {
    .popover:not(.minicart__custom-popover) {
        box-shadow: -5px 5px 5px -5px $black;
    }
}

.add-to-bag,
.minicart {
    padding: 0;
    background-color: transparent;

    .h1 {
        font-size: 1rem;
    }

    .cart {
        padding-top: 0.625em;
        padding-bottom: 0.625em;
        background-color: $white;

        .close {
            &:after {
                content: '';
            }
        }
    }

    .remove-btn {
        color: $slightly-darker-gray;
        float: right;
        background-color: $white;
        border: none;
        font-size: 1.625em;
        margin-top: -0.313em;
        padding: 0;
    }

    .product-summary {
        max-height: 21.875em;
        overflow-y: auto;
        overflow-x: hidden;

        @include media-breakpoint-up(lg) {
            max-height: 22.875em;
        }

        @include media-breakpoint-up(xl) {
            max-height: 32.875em;
        }
    }

    .card-body {
        padding: 0;
    }

    .unit-price-label {
        font-size: 1rem;
    }

    .quantity-label {
        font-size: 1rem;
    }

    .line-item-total-price-label {
        font-size: 1rem;
    }

    .minicart__custom-popover {
        position: fixed;
        top: 0 !important;
        height: 100vh;

        .cart {
            height: 100%;
            position: relative;

            .product-summary {
                max-height: 71vh;
            }

            .minicart-footer {
                position: absolute;
                bottom: 0;
                background-color: $white;
            }
        }
    }

    .popover {
        top: 78%; // magic number made to allow popover to not overlap mini-cart icon but allow cursor to move between mini-cart and button without breaking hover
        left: auto;
        right: 0;
        z-index: $z-index-minicart-popover;
        min-width: 23.44rem;
        max-width: 23.44rem;
        min-height: 22.7rem;
        display: none;
        border: none;

        &::before {
            left: auto;
            right: 15px;
        }

        &::after {
            left: auto;
            right: 16px;
        }

        &.show {
            display: block;
        }
    }

    .estimated-total {
        margin-top: 0.625em;
    }

    .sub-total-label {
        font-size: 1rem;
        font-weight: $font-weight-semiBold;
    }

    .sub-total {
        font-size: 1rem;
        font-weight: $font-weight-semiBold;
    }

    .line-item-divider {
        border-bottom: 0.5px solid $grey-d9;
    }

    .line-item-name {
        width: 90%;
    }

    .product-card__attr-label {
        color: $grey-75;
    }
}

#addToBagModal {
    .modal-dialog {
        margin: 0;

        @include media-breakpoint-down(md) {
            position: absolute;
            bottom: 0;
            width: 100%;
            max-width: 100%;
            height: 80vh;
        }

        @include media-breakpoint-up(lg) {
            margin: 1.75rem auto;
        }

        .modal-content {
            position: absolute;
            bottom: 0;
        }
    }

    .modal-content {
        border: none;

        .modal-header {
            border-bottom: 0.5px solid $grey-d9;
            background-color: $white;

            h1 {
                font-size: 1rem;
            }

            .close {
                padding: 0;
                margin: 0;

                &::after {
                    content: none;
                }
            }
        }

        .minicart-footer {
            p,
            .p {
                font-size: 1.125rem;
            }
        }

        .item-image {
            height: 5.625em;
            width: 5.625em;
        }
    }
}
