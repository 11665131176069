@import "../variables";
@import "bootstrap/scss/mixins/breakpoints";

.product-detail {
    .simple-quantity {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include media-breakpoint-down(sm) {
            flex-wrap: nowrap;
        }

        .quantity-selector__label {
            margin-right: 1rem;
        }

        .stepper {
            width: 9rem;
            height: 2.5rem;
        }
    }
}

.stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: min-content;
    height: 2.75rem;
    border: 0.5px solid $grey-d9;

    &__button {
        width: 2.75rem;
        height: 2.75rem;
        padding: 0;
        border: none;
        background: transparent;

        &:focus {
            outline: 0.5px solid $grey-d9;
        }
    }

    &__input {
        border: none;
        background: transparent;
        text-align: center;
        width: 2rem;
    }
}
