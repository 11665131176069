@import "menu";
@import "headerAside";

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

.site-header {
    position: sticky;
    top: 0;
    z-index: $z-index-sticky-header;
    background-color: white;
}

@include media-breakpoint-up(md) {
    .p-desktop-unset {
        position: unset;
    }
}

.navbar-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    // Hack the width to make the logo and right side buttons the same width so that the navigation is centred in the header.
    @include media-breakpoint-up(md) {
        .brand {
            min-width: 9.5rem;
        }
    }

    .global-actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        .minicart-link {
            position: relative;
            text-decoration: none;
        }
    }

    .search-icon,
    .account-icon,
    .minicart-icon {
        font-size: 1rem;
    }

    .search-action {
        &:focus {
            outline: 0.5px dashed #000;
            box-shadow: none;
        }
    }

    .account-action,
    .country-selector,
    .search-action,
    .minicart-link {
        display: inline-block;
        padding: 0;
        min-width: 2rem;
        min-height: 2rem;
        text-align: center;
    }

    .user,
    .country-selector,
    .minicart,
    .navbar-toggler {
        line-height: 2.25;
        height: auto;
    }

    .navbar-toggler {
        width: auto;
        min-height: 2.75rem;
        margin-left: -.75rem;
        font-size: 1.125rem;
    }

    .user {
        position: relative;

        .popover {
            position: absolute;
            display: none;
            padding: 1em;
            top: 85%;
            left: 0;

            a {
                white-space: nowrap;
                margin-bottom: 0.5em;
            }

            &::before {
                left: 1.5rem;
            }

            &::after {
                left: 1.5rem;
            }

            &.show {
                display: block;
            }
        }
    }

    .brand {
        @include media-breakpoint-down(sm) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .logo-home {
            display: block;

            img {
                width: 8.25rem;
                height: auto;

                @include media-breakpoint-down(sm) {
                    width: 5rem;
                }
            }
        }
    }
}

.main-menu {
    background-color: transparent;

    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
        position: static;
    }
}

.header-banner {
    background-color: $darker-gray;
    text-align: center;
    color: $white;

    .close-button {
        width: 1.5em + $banner-padding * 2;

        .close {
            opacity: 1;
            color: $white;
            width: 100%;
            height: 100%;
            background-color: #{var(--skin-primary-color-1)};
        }
    }

    .content {
        margin-right: 1.5em;
        padding-top: $banner-padding;
        padding-bottom: $banner-padding;
    }
}

.minicart {
    a.minicart-link {
        &:hover {
            text-decoration: none;
        }
    }

    .minicart-quantity {
        background-color: $menu-link;
        border-radius: 50%;
        width: 1.25em; /* 20/16 */
        height: 1.25em; /* 20/16 */
        line-height: normal;
        display: inline-block;
        text-align: center;
        font-size: 0.8125em; /* 13/16 */
        position: absolute;
        top: 0; /* 15/16 */
        right: 0; /* 15/16 */
        color: $white;
    }
}

a.normal {
    color: #{var(--skin-primary-color-1)};
    text-decoration: underline;
}

.slide-up {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    max-height: 100px;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.dropdown-country-selector {
    margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: #{var(--skin-link-color-1)};
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}

.checkout_navigation {
    display: flex;
    align-items: center;
    padding-top: .5rem;
    padding-bottom: .5rem;

    .logo-home img {
        width: 5rem;
        height: auto;

        @include media-breakpoint-up(md) {
            width: 8rem;
        }
    }
}
